/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../app/shared/shared.module';
import { SearchModule } from '../../app/shared/search/search.module';
import { FormsModule } from '@angular/forms';
import { RootModule } from '../../app/root.module';
import { NavbarModule } from '../../app/navbar/navbar.module';
import { ItemPageModule } from '../../app/item-page/item-page.module';
import { BrowseByMetadataPageComponent } from './app/browse-by/browse-by-metadata-page/browse-by-metadata-page.component';
import { BundleListElementComponent } from './app/shared/object-list/bundle-list-element/bundle-list-element.component';
import { ClaimedApprovedSearchResultListElementComponent } from './app/shared/object-list/my-dspace-result-list-element/claimed-search-result/claimed-approved-search-result/claimed-approved-search-result-list-element.component';
import { ClaimedDeclinedSearchResultListElementComponent } from './app/shared/object-list/my-dspace-result-list-element/claimed-search-result/claimed-declined-search-result/claimed-declined-search-result-list-element.component';
import { ClaimedTaskSearchResultDetailElementComponent } from './app/shared/object-detail/my-dspace-result-detail-element/claimed-task-search-result/claimed-task-search-result-detail-element.component';
import { CollectionGridElementComponent } from './app/shared/object-grid/collection-grid-element/collection-grid-element.component';
import { CollectionListElementComponent } from './app/shared/object-list/collection-list-element/collection-list-element.component';
import { CollectionSearchResultGridElementComponent } from './app/shared/object-grid/search-result-grid-element/collection-search-result/collection-search-result-grid-element.component';
import { CommunityGridElementComponent } from './app/shared/object-grid/community-grid-element/community-grid-element.component';
import { CommunityListElementComponent } from './app/shared/object-list/community-list-element/community-list-element.component';
import { CommunitySearchResultGridElementComponent } from './app/shared/object-grid/search-result-grid-element/community-search-result/community-search-result-grid-element.component';
import { ItemAdminSearchResultGridElementComponent } from './app/admin/admin-search-page/admin-search-results/admin-search-result-grid-element/item-search-result/item-admin-search-result-grid-element.component';
import { OrgUnitSearchResultListSubmissionElementComponent } from './app/entity-groups/research-entities/submission/item-list-elements/org-unit/org-unit-search-result-list-submission-element.component';
import { PersonSearchResultListSubmissionElementComponent } from './app/entity-groups/research-entities/submission/item-list-elements/person/person-search-result-list-submission-element.component';
import { PersonSidebarSearchListElementComponent } from './app/entity-groups/research-entities/item-list-elements/sidebar-search-list-elements/person/person-sidebar-search-list-element.component';
import { PoolSearchResultDetailElementComponent } from './app/shared/object-detail/my-dspace-result-detail-element/pool-search-result/pool-search-result-detail-element.component';
import { PoolSearchResultListElementComponent } from './app/shared/object-list/my-dspace-result-list-element/pool-search-result/pool-search-result-list-element.component';
import { WorkflowItemSearchResultAdminWorkflowListElementComponent } from './app/admin/admin-workflow-page/admin-workflow-search-results/admin-workflow-search-result-list-element/workflow-item/workflow-item-search-result-admin-workflow-list-element.component';
import { WorkflowItemSearchResultDetailElementComponent } from './app/shared/object-detail/my-dspace-result-detail-element/workflow-item-search-result/workflow-item-search-result-detail-element.component';
import { WorkflowItemSearchResultListElementComponent } from './app/shared/object-list/my-dspace-result-list-element/workflow-item-search-result/workflow-item-search-result-list-element.component';
import { WorkspaceItemSearchResultDetailElementComponent } from './app/shared/object-detail/my-dspace-result-detail-element/workspace-item-search-result/workspace-item-search-result-detail-element.component';
import { WorkspaceItemSearchResultListElementComponent } from './app/shared/object-list/my-dspace-result-list-element/workspace-item-search-result/workspace-item-search-result-list-element.component';
import { ComcolModule } from '../../app/shared/comcol/comcol.module';
import { AtmireAppModule } from '../../app-atmire/atmire-app.module';
import { AdminSearchModule } from '../../app/admin/admin-search-page/admin-search.module';
import { ResearchEntitiesModule } from '../../app/entity-groups/research-entities/research-entities.module';
import { AdminWorkflowModuleModule } from '../../app/admin/admin-workflow-page/admin-workflow.module';
import {
  CollectionSidebarSearchListElementComponent
} from './app/shared/object-list/sidebar-search-list-element/collection/collection-sidebar-search-list-element.component';
import {
  CommunitySidebarSearchListElementComponent
} from './app/shared/object-list/sidebar-search-list-element/community/community-sidebar-search-list-element.component';
import { CollectionFormModule } from '../../app/collection-page/collection-form/collection-form.module';
import { CommunityFormModule } from '../../app/community-page/community-form/community-form.module';
import {
  CreateCollectionPageComponent
} from './app/collection-page/create-collection-page/create-collection-page.component';
import {
  CreateCommunityPageComponent
} from './app/community-page/create-community-page/create-community-page.component';
import { AuthNavMenuComponent } from './app/shared/auth-nav-menu/auth-nav-menu.component';

/**
 * Add components that use a custom decorator to ENTRY_COMPONENTS as well as DECLARATIONS.
 * This will ensure that decorator gets picked up when the app loads
 */
const ENTRY_COMPONENTS = [
  BrowseByMetadataPageComponent,
  BundleListElementComponent,
  ClaimedApprovedSearchResultListElementComponent,
  ClaimedDeclinedSearchResultListElementComponent,
  ClaimedTaskSearchResultDetailElementComponent,
  CollectionGridElementComponent,
  CollectionListElementComponent,
  CollectionSearchResultGridElementComponent,
  CollectionSidebarSearchListElementComponent,
  CommunityGridElementComponent,
  CommunityListElementComponent,
  CommunitySearchResultGridElementComponent,
  CommunitySidebarSearchListElementComponent,
  ItemAdminSearchResultGridElementComponent,
  OrgUnitSearchResultListSubmissionElementComponent,
  PersonSearchResultListSubmissionElementComponent,
  PersonSidebarSearchListElementComponent,
  PoolSearchResultDetailElementComponent,
  PoolSearchResultListElementComponent,
  WorkflowItemSearchResultAdminWorkflowListElementComponent,
  WorkflowItemSearchResultDetailElementComponent,
  WorkflowItemSearchResultListElementComponent,
  WorkspaceItemSearchResultDetailElementComponent,
  WorkspaceItemSearchResultListElementComponent,
  AuthNavMenuComponent
];

const DECLARATIONS = [
  ...ENTRY_COMPONENTS,
  CreateCollectionPageComponent,
  CreateCommunityPageComponent,
];

@NgModule({
  imports: [
    AdminSearchModule,
    AdminWorkflowModuleModule,
    AtmireAppModule,
    CommonModule,
    ComcolModule,
    CollectionFormModule,
    CommunityFormModule,
    ResearchEntitiesModule,
    SharedModule,
    SearchModule,
    FormsModule,
    RootModule,
    NavbarModule,
    ItemPageModule,
  ],
  declarations: DECLARATIONS,
  providers: [
    ...ENTRY_COMPONENTS.map((component) => ({ provide: component }))
  ],
})
/**
 * This module is included in the main bundle that gets downloaded at first page load. So it should
 * contain only the themed components that have to be available immediately for the first page load,
 * and the minimal set of imports required to make them work. Anything you can cut from it will make
 * the initial page load faster, but may cause the page to flicker as components that were already
 * rendered server side need to be lazy-loaded again client side
 *
 * Themed EntryComponents should also be added here
 */
export class EagerThemeModule {
}

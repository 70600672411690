/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { Component } from '@angular/core';
import {
  SearchResultListElementComponent
} from '../../../../../../../app/shared/object-list/search-result-list-element/search-result-list-element.component';
import { Community } from '../../../../../../../app/core/shared/community.model';
import {
  CommunitySearchResult
} from '../../../../../../../app/shared/object-collection/shared/community-search-result.model';
import { ViewMode } from '../../../../../../../app/core/shared/view-mode.model';
import {
  listableObjectComponent
} from '../../../../../../../app/shared/object-collection/shared/listable-object/listable-object.decorator';
import { Context } from '../../../../../../../app/core/shared/context.model';

@Component({
  selector: 'ds-community-search-result-list-element-client',
  styleUrls: ['../../../../../../../app/shared/object-list/search-result-list-element/search-result-list-element.component.scss', './community-search-result-list-element.component.scss'],
  // styleUrls: ['../../../../../../../app/shared/object-list/search-result-list-element/search-result-list-element.component.scss', '../../../../../../../app/shared/object-list/search-result-list-element/community-search-result/community-search-result-list-element.component.scss'],
  // templateUrl: './community-search-result-list-element.component.html',
  templateUrl: '../../../../../../../app/shared/object-list/search-result-list-element/community-search-result/community-search-result-list-element.component.html',
})
/**
 * Component representing a community search result in list view
 */
@listableObjectComponent(CommunitySearchResult, ViewMode.ListElement, Context.Any, 'client')
export class CommunitySearchResultListElementComponent extends SearchResultListElementComponent<CommunitySearchResult, Community> {

}

<!--
  The contents of this file are subject to the license and copyright
  detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
  tree and available online at

  https://www.atmire.com/software-license/
 -->
<ds-themed-home-news></ds-themed-home-news>
<div class="container">
  <ng-container *ngIf="(site$ | async) as site">
    <ds-view-tracker [object]="site"></ds-view-tracker>
  </ng-container>
  <div class="pt-3">
    <ds-search-form [inPlaceSearch]="false"
                    [searchPlaceholder]="'home.search-form.placeholder' | translate">
    </ds-search-form>
  </div>
  <div class="collection-header py-2">
    <h2>{{ 'home.page.title' | translate }}</h2>
    <hr>
  </div>
  <ds-themed-community-list></ds-themed-community-list>
</div>

<!--
  The contents of this file are subject to the license and copyright
  detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
  tree and available online at

  https://www.atmire.com/software-license/
 -->
<ng-container *ngIf="show">
  <div class="row">
    <div class="col-sm-6 col-lg-5 col-xl-4">
      <ds-generic-item-page-field [item]="item"
                                  [fields]="TYPE"
                                  [label]="'item.page.fsc-type'">
      </ds-generic-item-page-field>
    </div>
    <div class="col-sm-6 col-lg-7 col-xl-8">
      <ds-generic-item-page-field [item]="item"
                                  [fields]="ZONE"
                                  [label]="'item.page.fsc-zone'">
      </ds-generic-item-page-field>
    </div>
  </div>
</ng-container>

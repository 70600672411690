/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { Component, Input, OnInit } from '@angular/core';
import { ItemPageFieldComponent } from '../../../../../app/item-page/simple/field-components/specific-field/item-page-field.component';
import { Metadata } from '../../../../../app/core/shared/metadata.utils';
import { Item } from '../../../../../app/core/shared/item.model';

@Component({
  selector: 'ds-item-page-forest-field',
  templateUrl: './item-page-forest-field.component.html',
  styleUrls: ['./item-page-forest-field.component.scss']
})
export class ItemPageForestFieldComponent {
  readonly TYPE: string[] = ['fscokr.forest.type', 'fsc.focus.forestType'];
  readonly ZONE: string[] = ['fscokr.forest.zone', 'fsc.focus.forestZone'];

  @Input()
  item: Item;

  get show(): boolean {
    return Metadata.has(this.item.metadata, this.TYPE) && Metadata.has(this.item.metadata, this.ZONE);
  }

  get type(): string {
    return Metadata.firstValue(this.item.metadata, this.TYPE);
  }

  get zone(): string {
    return Metadata.firstValue(this.item.metadata, this.ZONE);
  }
}

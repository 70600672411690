/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../app/shared/shared.module';
import { AtmireAppRoutingModule } from './atmire-app-routing.module';
import {
  AtmireObjectCollectionModule
} from './atmire-object-collection/atmire-object-collection.module';
import { AtmireItemPageModule } from './item-page/atmire-item-page.module';
import { MultilingualMetadataService } from './core/shared/multilingual-metadata.service';
import { AtmireComColFormComponent } from './shared/comcol/comcol-forms/comcol-form/atmire-comcol-form.component';
import { FormModule } from '../app/shared/form/form.module';
import { ComcolModule } from '../app/shared/comcol/comcol.module';
import { ComcolLanguagesComponent } from './shared/comcol/comcol-forms/edit-comcol-page/comcol-languages/comcol-languages.component';
import { CollectionLanguagesComponent } from './collection-page/edit-collection-page/collection-languages/collection-languages.component';
import { CommunityLanguagesComponent } from './community-page/edit-community-page/community-languages/community-languages.component';
import { CommunityFormModule } from '../app/community-page/community-form/community-form.module';
import { CollectionFormModule } from '../app/collection-page/collection-form/collection-form.module';
import { AtmireAbstractListableElementComponent } from './shared/object-collection/shared/object-collection-element/atmire-abstract-listable-element.component';
import { AtmireSearchResultListElementComponent } from './shared/object-list/search-result-list-element/atmire-search-result-list-element.component';
import { AtmireSearchResultDetailElementComponent } from './shared/object-detail/my-dspace-result-detail-element/atmire-search-result-detail-element.component';
import { AtmireSearchResultGridElementComponent } from './shared/object-grid/search-result-grid-element/atmire-search-result-grid-element.component';
import { AtmireSidebarSearchListElementComponent } from './shared/object-list/sidebar-search-list-element/atmire-sidebar-search-list-element.component';
import { MultilingualComcolPageContentComponent } from './shared/comcol/comcol-page-content/multilingual-comcol-page-content.component';
import { MultilingualComcolPageHeaderComponent } from './shared/comcol/comcol-page-header/multilingual-comcol-page-header.component';
import { LanguageSpecificMetadataValuesComponent } from './item-page/field-components/language-specific-metadata-values/language-specific-metadata-values.component';
import { MultilingualCollectionsComponent } from './item-page/field-components/multilingual-collections/multilingual-collections.component';
import { AtmireCreateComColPageComponent } from './shared/comcol/comcol-forms/create-comcol-page/atmire-create-comcol-page.component';
import { DSONameService } from '../app/core/breadcrumbs/dso-name.service';
import { AtmireDSONameService } from './core/breadcrumbs/atmire-dso-name-service';
import { LangSwitchService } from './shared/lang-switch/lang-switch.service';
import { SsoGuard } from './core/auth/sso-guard.service';
import { SsoRedirectPageComponent } from './core/auth/sso-redirect-page/sso-redirect-page.component';
import { AtmireAuthService } from './core/auth/atmire-auth.service';

/**
 * Declaration needed to make sure all decorator functions are called in time
 */
export const MODELS = [
];

const DECLARATIONS = [
  AtmireAbstractListableElementComponent,
  AtmireComColFormComponent,
  AtmireCreateComColPageComponent,
  AtmireSearchResultDetailElementComponent,
  AtmireSearchResultGridElementComponent,
  AtmireSearchResultListElementComponent,
  AtmireSidebarSearchListElementComponent,
  ComcolLanguagesComponent,
  CollectionLanguagesComponent,
  CommunityLanguagesComponent,
  LanguageSpecificMetadataValuesComponent,
  MultilingualCollectionsComponent,
  MultilingualComcolPageContentComponent,
  MultilingualComcolPageHeaderComponent,
  SsoRedirectPageComponent
];

/**
 * Add components that use a custom decorator to ENTRY_COMPONENTS as well as DECLARATIONS. This will
 * ensure that decorator gets picked up when the app loads
 */
const ENTRY_COMPONENTS = [
];

const PROVIDERS = [
  MultilingualMetadataService,
  { provide: DSONameService, useClass: AtmireDSONameService },
  LangSwitchService,
  AtmireAuthService,
  SsoGuard,
];

@NgModule({
  declarations: [
    ...DECLARATIONS,
  ],
  providers: [
    ...PROVIDERS,
    ...ENTRY_COMPONENTS.map((component) => ({ provide: component }))
  ],
  imports: [
    CollectionFormModule,
    CommunityFormModule,
    ComcolModule,
    CommonModule,
    FormModule,
    SharedModule,
    AtmireAppRoutingModule,
    AtmireObjectCollectionModule,
    AtmireItemPageModule,
  ],
  exports: [
    ...DECLARATIONS,
  ]
})
export class AtmireAppModule {
}

/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { Component } from '@angular/core';
import {
  SearchResultListElementComponent
} from '../../../../../../../app/shared/object-list/search-result-list-element/search-result-list-element.component';
import { Collection } from '../../../../../../../app/core/shared/collection.model';
import {
  CollectionSearchResult
} from '../../../../../../../app/shared/object-collection/shared/collection-search-result.model';

import { ViewMode } from '../../../../../../../app/core/shared/view-mode.model';
import {
  listableObjectComponent
} from '../../../../../../../app/shared/object-collection/shared/listable-object/listable-object.decorator';
import { Context } from '../../../../../../../app/core/shared/context.model';

@Component({
  selector: 'ds-collection-search-result-list-element-client',
  styleUrls: ['../../../../../../../app/shared/object-list/search-result-list-element/search-result-list-element.component.scss', './collection-search-result-list-element.component.scss'],
  // styleUrls: ['../../../../../../../app/shared/object-list/search-result-list-element/search-result-list-element.component.scss', '../../../../../../../app/shared/object-list/search-result-list-element/collection-search-result/collection-search-result-list-element.component.scss'],
  // templateUrl: './collection-search-result-list-element.component.html',
  templateUrl: '../../../../../../../app/shared/object-list/search-result-list-element/collection-search-result/collection-search-result-list-element.component.html',
})
/**
 * Component representing a collection search result in list view
 */
@listableObjectComponent(CollectionSearchResult, ViewMode.ListElement, Context.Any, 'client')
export class CollectionSearchResultListElementComponent extends SearchResultListElementComponent<CollectionSearchResult, Collection> {
}

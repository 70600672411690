<div class="d-flex flex-row">
  <ds-themed-item-page-title-field [item]="object" class="mr-auto">
  </ds-themed-item-page-title-field>
  <div class="pl-2 space-children-mr">
    <ds-dso-page-version-button (newVersionEvent)="onCreateNewVersion()" [dso]="object"
                                [tooltipMsgCreate]="'item.page.version.create'"
                                [tooltipMsgHasDraft]="'item.page.version.hasDraft'"></ds-dso-page-version-button>
    <ds-dso-page-edit-button [pageRoute]="itemPageRoute" [dso]="object" [tooltipMsg]="'journalissue.page.edit'"></ds-dso-page-edit-button>
  </div>
</div>
<div class="row">
  <div class="col-xs-12 col-md-4">
    <ds-metadata-field-wrapper [hideIfNoTextContent]="false">
      <ds-themed-thumbnail [thumbnail]="object?.thumbnail | async"></ds-themed-thumbnail>
    </ds-metadata-field-wrapper>
    <ds-themed-generic-item-page-field [item]="object"
      [fields]="['publicationvolume.volumeNumber']"
      [label]="'journalvolume.page.volume'">
    </ds-themed-generic-item-page-field>
    <ds-themed-generic-item-page-field [item]="object"
      [fields]="['publicationissue.issueNumber']"
      [label]="'journalissue.page.number'">
    </ds-themed-generic-item-page-field>
    <ds-themed-generic-item-page-field [item]="object"
      [fields]="['creativework.datePublished']"
      [label]="'journalissue.page.issuedate'">
    </ds-themed-generic-item-page-field>
    <ds-themed-generic-item-page-field [item]="object"
      [fields]="['journal.title']"
      [label]="'journalissue.page.journal-title'">
    </ds-themed-generic-item-page-field>
    <ds-themed-generic-item-page-field [item]="object"
      [fields]="['creativeworkseries.issn']"
      [label]="'journalissue.page.journal-issn'">
    </ds-themed-generic-item-page-field>
  </div>
  <div class="col-xs-12 col-md-6">
    <ds-related-items
      [parentItem]="object"
      [relationType]="'isJournalVolumeOfIssue'"
      [label]="'relationships.isSingleVolumeOf' | translate">
    </ds-related-items>
    <ds-related-items
      class="mb-1 mt-1"
      [parentItem]="object"
      [relationType]="'isPublicationOfJournalIssue'"
      [label]="'relationships.isPublicationOfJournalIssue' | translate">
    </ds-related-items>
    <ds-themed-generic-item-page-field [item]="object"
      [fields]="['dc.description']"
      [label]="'journalissue.page.description'">
    </ds-themed-generic-item-page-field>
    <ds-themed-generic-item-page-field [item]="object"
      [fields]="['creativework.keywords']"
      [label]="'journalissue.page.keyword'">
    </ds-themed-generic-item-page-field>
    <div>
      <a class="btn btn-outline-primary" [routerLink]="[itemPageRoute + '/full']">
        {{"item.page.link.full" | translate}}
      </a>
    </div>
  </div>
</div>

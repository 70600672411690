<!--
  The contents of this file are subject to the license and copyright
  detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
  tree and available online at

  https://www.atmire.com/software-license/
 -->
<div class="row" *ngIf="iiifEnabled">
  <div class="col-12">
    <ds-mirador-viewer id="iiif-viewer"
                       [object]="object"
                       [searchable]="iiifSearchEnabled"
                       [query]="iiifQuery$ | async">
    </ds-mirador-viewer>
  </div>
</div>
<div class="d-flex flex-row">
  <h2 class="item-page-title-field mr-auto">{{ dsoNameService.getName(object) }}</h2>
  <div class="pl-2 space-children-mr">
    <ds-dso-page-version-button (newVersionEvent)="onCreateNewVersion()" [dso]="object"
                                [tooltipMsgCreate]="'item.page.version.create'"
                                [tooltipMsgHasDraft]="'item.page.version.hasDraft'"></ds-dso-page-version-button>
    <ds-dso-page-edit-button [pageRoute]="itemPageRoute" [dso]="object" [tooltipMsg]="'item.page.edit'"></ds-dso-page-edit-button>
  </div>
</div>
<div class="row">
  <div class="col-xs-12 col-md-4">
    <ng-container *ngIf="!mediaViewer.image">
      <ds-metadata-field-wrapper [hideIfNoTextContent]="false">
        <ds-themed-thumbnail [thumbnail]="object?.thumbnail | async"></ds-themed-thumbnail>
      </ds-metadata-field-wrapper>
    </ng-container>
    <div *ngIf="mediaViewer.image" class="mb-2">
      <ds-themed-media-viewer [item]="object" [videoOptions]="mediaViewer.video"></ds-themed-media-viewer>
    </div>
    <ds-themed-item-page-file-section [item]="object"></ds-themed-item-page-file-section>
    <ds-item-page-link-field [mdValues]="object.allMetadata('is.link.url')" [label]="'item.page.link.url'"></ds-item-page-link-field>
    <ds-item-page-date-field [item]="object"></ds-item-page-date-field>

    <ds-generic-item-page-field [item]="object"
                                [fields]="['dc.date.submitted', 'dcterms.dateSubmitted']"
                                [label]="'item.page.date-submitted'">
    </ds-generic-item-page-field>

    <ds-metadata-representation-list class="ds-item-page-mixed-author-field"
                                     [parentItem]="object"
                                     [itemType]="'Person'"
                                     [metadataFields]="['dc.contributor.author', 'dc.creator', 'dc.contributor']"
                                     [label]="'relationships.isAuthorOf' | translate">
    </ds-metadata-representation-list>

    <ds-generic-item-page-field [item]="object"
                                [fields]="['dcterms.type']"
                                [label]="'item.page.type'">
    </ds-generic-item-page-field>

    <ds-generic-item-page-field [item]="object"
                                [fields]="['fscdoc.version.number', 'is.extent.version']"
                                [label]="'item.page.fsc-version-number'">
    </ds-generic-item-page-field>

    <ds-generic-item-page-field [item]="object"
                                [fields]="['fscdoc.status', 'is.item.status']"
                                [label]="'item.page.fsc-status'">
    </ds-generic-item-page-field>

    <ds-metadata-field-wrapper [label]="'item.page.metadata' | translate">
      <a class="btn btn-outline-primary" [routerLink]="[itemPageRoute + '/full']" role="button">
        <i class="fas fa-info-circle"></i> {{"item.page.link.full" | translate}}
      </a>
    </ds-metadata-field-wrapper>
  </div>
  <div class="col-xs-12 col-md-6">
    <ds-themed-generic-item-page-field [item]="object"
                                       [enableMarkdown]="true"
                                       [fields]="['dc.description.abstract', 'dcterms.abstract']">
    </ds-themed-generic-item-page-field>

    <ds-themed-generic-item-page-field [item]="object"
                                       [fields]="['dc.subject', 'fsc.subject']"
                                       [separator]="', '"
                                       [label]="'item.page.subject'">
    </ds-themed-generic-item-page-field>

    <ds-themed-generic-item-page-field [item]="object"
                                       [fields]="['dc.description.sponsorship', 'is.contributor.funder']"
                                       [label]="'item.page.sponsorship'">
    </ds-themed-generic-item-page-field>

    <ds-themed-generic-item-page-field [item]="object"
                                       [fields]="['dc.description', 'dc.terms.description']"
                                       [enableMarkdown]="true"
                                       [label]="'item.page.description'">
    </ds-themed-generic-item-page-field>

    <ds-item-page-uri-field [item]="object"
                            [fields]="['dc.identifier.uri']"
                            [label]="'item.page.uri'">
    </ds-item-page-uri-field>

    <ds-item-page-identifiers-field [item]="object"
                                    [label]="'item.page.fsc-identifiers'">
    </ds-item-page-identifiers-field>

    <ds-item-page-forest-field [item]="object">
    </ds-item-page-forest-field>

    <ds-generic-item-page-field [item]="object"
                                [fields]="['fscdoc.code', 'is.identifier.standardCode']"
                                [label]="'item.page.fsc-code'">
    </ds-generic-item-page-field>

    <ds-generic-item-page-field [item]="object"
                                [fields]="['dc.date.valid', 'dcterms.valid']"
                                [label]="'item.page.fsc-effective-date'">
    </ds-generic-item-page-field>

    <ds-generic-item-page-field [item]="object"
                                [fields]="['fscdoc.review.year', 'is.dateReviewed']"
                                [label]="'item.page.fsc-review-year'">
    </ds-generic-item-page-field>
    <ds-generic-item-page-field [item]="object"
                                [fields]="['is.pesticide.alternativeStrategy']"
                                [label]="'item.page.is.pesticide.alternativeStrategy'">
    </ds-generic-item-page-field>
    <ds-generic-item-page-field [item]="object"
                                [fields]="['is.pesticide.alternativeType']"
                                [label]="'item.page.is.pesticide.alternativeType'">
    </ds-generic-item-page-field>
    <ds-generic-item-page-field [item]="object"
                                [fields]="['is.pesticide.pestType']"
                                [label]="'item.page.is.pesticide.pestType'">
    </ds-generic-item-page-field>
    <ds-generic-item-page-field [item]="object"
                                [fields]="['is.pesticide.alternativeTrial']"
                                [label]="'item.page.is.pesticide.alternativeTrial'">
    </ds-generic-item-page-field>
    <ds-generic-item-page-field [item]="object"
                                [fields]="['is.coverage.country']"
                                [label]="'item.page.is.coverage.country'">
    </ds-generic-item-page-field>
    <ds-generic-item-page-field [item]="object"
                                [fields]="['is.pesticide.alternativeMethod']"
                                [label]="'item.page.is.pesticide.alternativeMethod'">
    </ds-generic-item-page-field>
    <ds-generic-item-page-field [item]="object"
                                [fields]="['is.pesticide.activeingredient']"
                                [label]="'item.page.is.pesticide.activeingredient'">
    </ds-generic-item-page-field>
    <ds-item-page-link-field [mdValues]="object.allMetadata('is.identifier.doi')" [label]="'item.page.link.doi'"></ds-item-page-link-field>
    <ds-item-page-multilingual-collections [item]="object"></ds-item-page-multilingual-collections>
  </div>
</div>

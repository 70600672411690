<!--
  The contents of this file are subject to the license and copyright
  detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
  tree and available online at

  https://www.atmire.com/software-license/
 -->
<ds-truncatable [id]="dso.id" *ngIf="object !== undefined && object !== null">
  <div class="d-flex flex-nowrap item-list">
    <div class="item-search-result-list-element-thumbnail-container" *ngIf="showThumbnail">
      <ds-themed-thumbnail class="item-list-thumbnail" [thumbnail]="dso?.thumbnail | async" [limitWidth]="false">
      </ds-themed-thumbnail>
    </div>
    <div>
      <a *ngIf="linkType != linkTypes.None" [target]="(linkType == linkTypes.ExternalLink) ? '_blank' : '_self'"
         rel="noopener noreferrer" [routerLink]="[itemPageRoute]" class="lead item-list-title" [innerHTML]="dsoTitle">
      </a>
      <span *ngIf="linkType == linkTypes.None" class="lead item-list-title" [innerHTML]="dsoTitle"></span>

      <span class="d-block text-muted my-2">
        <span *ngIf="code" class="d-block">
          {{ 'item-search-result-list-element.code' | translate }}: {{ code }}
        </span>
        <span *ngIf="authors.length > 0; else unknownAuthors" class="item-list-authors">
          <ng-container *ngFor="let author of authors; let last=last;">
            {{ author.value + (last ? '' : '; ') }}
          </ng-container>
        </span>
        <ng-template #unknownAuthors class="d-block">
          <span>{{ 'item-search-result-list-element.unknownAuthors' | translate }}</span>
        </ng-template>


        <ng-container *ngIf="publishers.length !== 0 || date">
          <span *ngIf="publishers.length !== 0" class="item-list-publisher">
            <ng-container *ngFor="let publisher of publishers; let i = index">
              {{ (i === 0 ? '(' : '') + publisher + (publishers.length === 0 || (publishers.length - 1 === i && !date) ? ')' : ',') }}
            </ng-container>
          </span>

          <span *ngIf="date" class="item-list-date">
            {{ (publishers.length === 0 ? '(' : '') + date.substr(0, 10) }})
          </span>
        </ng-container>
      </span>

      <span *ngFor="let type of allMetadataValues('dcterms.type')" class="item-list-type mt-2">
        {{ 'item-search-result-list-element.type' | translate }} {{ type }}
      </span>

      <p *ngIf="firstMetadataValue(['dc.description.abstract', 'dcterms.abstract']) as abstract" class="mt-2">
        <ds-truncatable>
          <ds-truncatable-part [id]="dso.id" [minLines]="3">
            <span [innerHTML]="abstract"></span>
          </ds-truncatable-part>
        </ds-truncatable>
      </p>
    </div>
  </div>
</ds-truncatable>

/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { Component, Input, OnInit } from '@angular/core';
import { ItemPageFieldComponent } from '../../../../../app/item-page/simple/field-components/specific-field/item-page-field.component';
import { Metadata } from '../../../../../app/core/shared/metadata.utils';

const PREFIX = 'item.page.fsc-identifier.';
const IDENTIFIERS = [
  'dc.identifier.issn',
  'dc.identifier.eissn',
  'dc.identifier.other',
  'dc.identifier.ismn',
  'dc.identifier.govdoc',
  'dc.identifier.isbn',
  'dc.identifier.pmid',
  'dc.identifier.pmcid',
  'dc.identifier.doi',
  'dc.identifier.certificatecode',
];

interface Identifier {
  prefix: string;
  id: string;
}

@Component({
  selector: 'ds-item-page-identifiers-field',
  templateUrl: './item-page-identifiers-field.component.html',
  styleUrls: ['./item-page-identifiers-field.component.scss']
})
export class ItemPageIdentifiersFieldComponent extends ItemPageFieldComponent {
  /**
   * Fields (schema.element.qualifier) used to render their values.
   */
  @Input() fields: string[] = IDENTIFIERS;

  /**
   * Label i18n key for the rendered metadata
   */
  @Input() label: string;

  get identifiers(): Identifier[] {
    return this.fields
               .filter(field => Metadata.has(this.item.metadata, field))
               .map(field => {

                 return {
                   prefix: PREFIX + field,
                   id: Metadata.firstValue(this.item.metadata, field)
                 };
               });
  }
}

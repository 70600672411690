<!--
  The contents of this file are subject to the license and copyright
  detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
  tree and available online at

  https://www.atmire.com/software-license/
 -->
<div class="item-page-field">
  <ds-metadata-field-wrapper [label]="label | translate">
    <span *ngFor="let identifier of identifiers; let last=last;">
      {{ identifier.prefix | translate }}: {{identifier.id}}<span *ngIf="!last" [innerHTML]="separator"></span>
    </span>
  </ds-metadata-field-wrapper>
</div>

/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { Component } from '@angular/core';
import { MetadataUriValuesComponent } from '../../../../../app/item-page/field-components/metadata-uri-values/metadata-uri-values.component';

@Component({
  selector: 'ds-item-page-link-field',
  templateUrl: './item-page-link-field.component.html',
  styleUrls: ['./item-page-link-field.component.scss']
})
/**
 * Component to display metadata values as links on item pages
 */
export class ItemPageLinkFieldComponent extends MetadataUriValuesComponent {

  constructor() {
    super();
  }
}
